import React from "react";
import { motion } from "framer-motion";
import { List } from "./components/List";


function App() {
  
  return (
    <div className="App">
      <div className="container-framer">
        <motion.div layout>
               {/* PER TORNARE ALLA VERSIONE LOCALE CAMBIARE URL DELLA CONDIZIONE CON "LOCALHOST:3000" */}
              {window.location.href === 'https://react.canenerotest.com/' && <List />} 
        </motion.div>
      </div>
      
    </div>
  );
}

export default App;
