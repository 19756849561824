/* eslint-disable no-lone-blocks */
import React from "react";
import { motion } from "framer-motion";
import { items } from "../data";

export function Item({ id }) {
  const item = items.find((item) => item.id === id);

  // Se l'oggetto 'item' non è definito, esci dal componente o gestisci il caso in modo adeguato
  if (!item) {
    return console.log("item non trovato");
  }
  const header = document.querySelector(".header-menu");
  const divFramer = document.querySelector(".overlay-framer");
  // eslint-disable-next-line no-unused-expressions
  divFramer ? (header.style.display = "none") : null;
  const { category, title, titleContent, text, url, close } = item;

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        style={{ pointerEvents: "auto" }}
        className="overlay-framer"
      ></motion.div>
      <div className="card-content-container open">
        <motion.div
          className="card-content card-open-link"
          layoutId={`card-container-${id}`}
        >
          <motion.div
            className="card-image-container"
            layoutId={`card-image-container-${id}`}
          >
            <img className="card-image" src={`/images/${id}.jpg`} alt="" />
          </motion.div>
          <motion.div
            className="title-container"
            layoutId={`title-container-${id}`}
          >
            <motion.a href={close}>
              <motion.button
                className="button-motion close-button"
              >
                X
              </motion.button>
            </motion.a>

            <h2 className="item-category">{category}</h2>
            <h2 className="item-title">{title}</h2>
          </motion.div>
          <motion.div className="content-container" animate>
            <h3 className="item-subtitle">{titleContent}</h3>
            <p className="item-paragraph">{text}</p>
          </motion.div>
          <motion.div>
            <motion.a href={url}>
              <motion.button className="button-motion">
                See Article
              </motion.button>
            </motion.a>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
}
