import React, { useState } from "react";
import { items } from "../data";
import { motion } from "framer-motion";
import { Item } from "./Item";

function Card({ id, title, category, theme }) {
  const [popup, setPopup] = useState(false);
  const changePopup = () =>  {
    setPopup(!popup);
    document.body.classList.toggle('overflow-hidden')
    const headerNav = document.querySelector('.header-menu');
    headerNav.classList.toggle('none'); // TOGLIERE QUANDO SI LAVORA IN DEVELOPMENT
  };
  
   const cardContainer ='card-content-container';
  return (
    <> 
    <li onClick={changePopup} className={`card ${theme} `}>
      <div className={cardContainer}>{/*MODIFICARE CLASSE PER IMPOSTARE OVERFLOW */}
        <motion.div className="card-content-list" layoutId={`card-container-${id}`}>
          <motion.div
            className="card-image-container"
            layoutId={`card-image-container-${id}`}
          >
            <img className="card-image" src={`/images/${id}.jpg`} alt="" />
          </motion.div>
          <motion.div
            className="title-container"
            layoutId={`title-container-${id}`}
          >
            <h2 className="list-category">{category}</h2>
            <p className="list-title">{title}</p>
          </motion.div>
        </motion.div>
      </div>
      {popup && <Item id={id} />}
    </li>
    </>
  );
}

export function List({ selectedId }) {
  return (
    <ul className="card-list ">
      {items.map((card) => (
        <Card key={card.id} {...card} isSelected={card.id === selectedId} />
      ))}
    </ul>
  );
}
