export const items = [
    // Photo by ivan Torres on Unsplash
    {
      id: "foto01",
      category: "ARVAL",
      title: "Brand Auditing, Brand Strategy, Creative contents, Media production, Web development",
      titleContent: "Getting a breath of fresh air or soaking up the art scene",
      text: "This is an innovative coastal town bursting with life and offering amazing experiences. It is a place with many faces, but one that has always retained its DNA: quality and class. And it now radiates that DNA in a strong, uniform brand that can be used flexibly. Versatile yet unified. Because those words clearly reflect what Knokke-Heist is all about.",
      pointOfInterest: 80,
      backgroundColor: "#814A0E",
      url: "arval.php",
      close: "/"
    },
    // Photo by Dennis Brendel on Unsplash
    {
      id: "foto02",
      category: "GROTTINI",
      title: "Arrange Your Apple Devices for the Gram",
      titleContent: "Challenge",
      text: "Più di quaranta anni di esperienza. Migliaia di progetti in tutto il mondo. Partner dei brand più noti per creare ambienti e spazi nei settori retail, marine, hospitality e automotive.",
      pointOfInterest: 120,
      backgroundColor: "#959684",
      url: "grottini.php",
      close: "/"
    },
    // Photo by Alessandra Caretto on Unsplash
    {
      id: "foto03",
      category: "BAYER",
      title: "Social media marketing, Digital advertising",
      titleContent: "Solution",
      text: "Seguire le linee guide global corporate Bayer, adattandole al contesto culturale italiano. Azioni mirate di digital strategy, un piano editoriali e campagne ottimizzati in base a stagionalità, eventi e innovazioni dal mondo Bayer.",
      pointOfInterest: 260,
      backgroundColor: "#5DBCD2",
      url: "bayer.php",
      close: "/"
    },
    // Photo by Taneli Lahtinen on Unsplash
    {
      id: "foto04",
      category: "DEKALB",
      title: "Social media marketing, Digital advertising",
      titleContent: "Challenge",
      text: "Comunicare il brand nei social, brand radicato ma con una comunicazione troppo tecica, puntata su prodotto. Allargare la community online dei social a fasce d'età più giovani. Valorizzare la mission",
      pointOfInterest: 200,
      backgroundColor: "#8F986D",
      url: "dekalb.php",
      close: "/"
    }
    
 
  ];
  
  export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
  